import type { Key } from '@react-types/shared'
import {
  createEntityAdapter,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit/react'
import collect from 'collect.js'

import { projectsGalleryReceived } from '@/store/features/gallery/gallery-images-actions'
import type { GalleryProjectsNode } from '@/store/features/gallery/gallery-query-types'
import type { SidebarNavigationItems } from '@/types/sidebar'

export const sidebarAdapter = createEntityAdapter<SidebarNavigationItems>()

export type SidebarState = {
  currentSection: Set<Key>
  currentItem: ''
}

export const sidebarInitialState = sidebarAdapter.getInitialState<SidebarState>(
  {
    currentSection: new Set(['2023']),
    currentItem: '',
  },
)

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: sidebarInitialState,
  reducers: {
    setCurrentSection: (state, action: PayloadAction<Set<Key>>) => {
      state.currentSection = action.payload
    },
    setCurrentItem: (state, action) => {
      state.currentItem = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(projectsGalleryReceived, (state, action) => {
      const payloadData = collect(action.payload.mediaGalleries.nodes)
      const sections = payloadData
        .mapToGroups((group: GalleryProjectsNode) => [
          group.year,
          {
            id: group.databaseId,
            name: group.title,
            href: group.slug,
            itemType: 'button',
          },
        ])
        .map((item, key) => ({
          id: key,
          name: key,
          children: item,
        }))
        .values()
        .sortByDesc('id')
        .all() as SidebarNavigationItems[]
      sidebarAdapter.addMany(state, sections)
    })
  },
})

export const { setCurrentSection, setCurrentItem } = sidebarSlice.actions

export default sidebarSlice.reducer
