import { useTranslation } from 'gatsby-plugin-react-i18next'
import * as m from 'motion/react-m'

import { AppLogo } from '@/components/site-components/app-logo'
import { Spinner } from '@/components/ui/svg-patterns/spinner'

export function LoadingSpinner() {
  const { t } = useTranslation()
  return (
    <m.div
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.5,
        delay: 1,
      }}
      className={
        'fixed top-0 z-50 flex h-svh w-full bg-gray-950 dark:bg-gray-900'
      }
    >
      <div className="absolute z-50 flex h-screen w-full flex-col items-center justify-center">
        <div className="space-y-4 text-center">
          <AppLogo
            className={'mb-8 h-12 w-auto text-slate-100 dark:text-white'}
          />
          <div
            role="status"
            className={'flex items-center justify-center gap-2'}
          >
            <Spinner variant={'large'} colorScheme={'secondary'} />
            <span className="animate-pulse text-2xl font-bold tracking-tight text-slate-100 dark:text-white">
              {t('loading')}
            </span>
          </div>
        </div>
      </div>
    </m.div>
  )
}
