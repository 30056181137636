import { type ReactNode } from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'

import AppReduxProvider from '@/components/site-components/app-redux-provider'
import AppSuspenseProvider from '@/components/site-components/app-suspense-provider'

export function AppProvider({ children }: { children: ReactNode }) {
  return (
    <AppSuspenseProvider>
      <AppReduxProvider>
        <SkeletonTheme baseColor="#1e293b" highlightColor="#64748b">
          {children}
        </SkeletonTheme>
      </AppReduxProvider>
    </AppSuspenseProvider>
  )
}
