import { isAnyOf } from '@reduxjs/toolkit/react'

import { api } from '@/store/app-api'
import {
  allPostsReceived,
  postReceived,
} from '@/store/features/posts/posts-actions'
import type { GetAllPostsQuery, PostsQuery } from '@/types/posts-types'

import { ALL_POSTS_QUERY, GET_POST_QUERY } from './posts-schema'

export const postsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllPosts: build.query<GetAllPostsQuery, undefined>({
      query: () => ({
        document: ALL_POSTS_QUERY,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const response = await queryFulfilled
        if (response.data?.posts) {
          const allPosts = response.data.posts.nodes
          dispatch(allPostsReceived(allPosts))
        }
      },
    }),
    getPost: build.query<{ post: PostsQuery }, string>({
      query: (slug: string) => ({
        document: GET_POST_QUERY,
        variables: {
          id: slug,
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const res = await queryFulfilled
        const post = res.data.post
        dispatch(postReceived(post))
      },
    }),
  }),
})

export const {
  useGetAllPostsQuery,
  useGetPostQuery,
  endpoints: { getPost },
} = postsApi

export const allPostsMatcher = isAnyOf(
  postsApi.endpoints.getAllPosts.matchFulfilled,
  allPostsReceived,
)

export const postMatcher = isAnyOf(
  postsApi.endpoints.getPost.matchFulfilled,
  postReceived,
)
