import { createEntityAdapter } from '@reduxjs/toolkit/react'

import { api } from '@/store/app-api'
import {
  galleryReceived,
  projectsGalleryReceived,
} from '@/store/features/gallery/gallery-images-actions'
import type {
  GalleryEntity,
  GetGalleryBySlugQuery,
  GetProjectsGalleryQuery,
} from '@/store/features/gallery/gallery-query-types'
import {
  GET_GALLERY_BY_SLUG,
  GET_PROJECTS_GALLERIES,
} from '@/store/features/gallery/gallery-schema'

export const galleryImagesAdapter = createEntityAdapter({
  selectId: (image: GalleryEntity) => image.slug,
})

export const galleryApi = api.injectEndpoints({
  endpoints: (build) => ({
    getGalleryBySlug: build.query<GetGalleryBySlugQuery, string>({
      query: (slug: string) => ({
        document: GET_GALLERY_BY_SLUG,
        variables: {
          id: slug,
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const res = await queryFulfilled
        dispatch(galleryReceived(res.data))
      },
    }),
    getProjectsGallery: build.query<GetProjectsGalleryQuery, undefined>({
      query: () => ({
        document: GET_PROJECTS_GALLERIES,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const res = await queryFulfilled
        dispatch(projectsGalleryReceived(res.data))
      },
    }),
  }),
})

export const { useGetGalleryBySlugQuery, useGetProjectsGalleryQuery } =
  galleryApi
