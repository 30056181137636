import { AnimatePresence } from 'motion/react'
import * as m from 'motion/react-m'
import { type PropsWithChildren } from 'react'

const variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.5,
      when: 'beforeChildren',
      ease: 'easeOut',
    },
  },
  exit: { opacity: 0, transition: { duration: 0.4, ease: 'easeOut' } },
}

const PageTransition = ({
  page,
  children,
}: PropsWithChildren<{ page: string }>) => {
  return (
    <AnimatePresence mode={'wait'}>
      <m.main
        key={page}
        layout
        className="relative flex w-full flex-col"
        initial={'initial'}
        animate={'animate'}
        exit={'exit'}
        variants={variants}
      >
        {children}
      </m.main>
    </AnimatePresence>
  )
}
export default PageTransition
