import type { NavigateOptions } from '@reach/router'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { startTransition } from 'react'

export default function useNavigate() {
  const { navigate } = useI18next()
  function navigateAction(
    to: string,
    options?: NavigateOptions<Record<string, unknown>>,
  ) {
    startTransition(() => {
      void navigate(to, options)
    })
  }
  return { navigateAction }
}
