import * as m from 'motion/react-m'
import { type ComponentPropsWithoutRef } from 'react'
import type { HeadingProps as AriaHeadingProps } from 'react-aria-components'
import { Heading as RACHeading } from 'react-aria-components'

import { textVariants } from '@/components/ui/elements/variants/text-variants'
import type { TextVariants } from '@/types/style-variants-types'

const MotionHeadingComponent = m.create(RACHeading)

export interface HeadingProps extends AriaHeadingProps, TextVariants {
  asMotion?: boolean
  motionProps?: ComponentPropsWithoutRef<typeof MotionHeadingComponent>
}

export function Heading({
  intent,
  colorScheme,
  size,
  className,
  asMotion,
  motionProps,
  children,
  ...props
}: HeadingProps) {
  if (asMotion) {
    return (
      <MotionHeadingComponent
        {...motionProps}
        className={textVariants({ intent, colorScheme, size, className })}
      >
        {children}
      </MotionHeadingComponent>
    )
  }
  return (
    <RACHeading
      {...props}
      className={textVariants({ intent, colorScheme, size, className })}
    >
      {children}
    </RACHeading>
  )
}
