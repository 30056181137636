import { useCallback } from 'react'

import { IS_SERVER } from '@/lib/constants'

export function useScrollTo(elementId?: string) {
  const handlePress = useCallback(() => {
    if (elementId) {
      const el = !IS_SERVER && document.getElementById(elementId)
      el && el.scrollIntoView({ behavior: 'smooth' })
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [elementId])

  return { handlePress }
}
