import './src/styles/global.css'
import '@fontsource-variable/montserrat'
import 'react-loading-skeleton/dist/skeleton.css'

import type { GatsbyBrowser } from 'gatsby'

import { wrapWithLayout } from './wrapWithLayout'
import { wrapWithProvider } from './wrapWithProvider'

export const wrapPageElement = wrapWithLayout

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] =
  wrapWithProvider

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  routerProps: { location },
}) => {
  if (location.state && Object.hasOwn(location.state, 'updateScroll')) {
    return location.state.updateScroll
  }
  return true
}
