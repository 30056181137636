import collect from 'collect.js'
import type { GatsbyLinkProps } from 'gatsby'
import type { AppLanguages } from 'gatsby-plugin-react-i18next'
import type { ComponentPropsWithRef, ComponentType } from 'react'
import { type LinkProps as AriaLinkProps } from 'react-aria-components'
import { tv, type VariantProps } from 'tailwind-variants'

import { textVariants } from '@/components/ui/elements/variants/text-variants'
import type { ButtonStyleAttributes } from '@/types/style-variants-types'
import { ButtonType } from '@/types/style-variants-types'

export const baseLink = tv({
  base: 'focus:outline-none disabled:text-slate-400',
  extend: textVariants,
  variants: {
    hoverStyle: {
      true: 'transition-colors hover:text-brand-600 hover:delay-75 dark:hover:text-brand-600',
    },
    isCurrent: {
      true: `text-brand-600 hover:text-white`,
    },
  },
})

export type BaseLinkVariants = VariantProps<typeof baseLink>

export const baseBtn = tv({
  base: 'relative isolate inline-flex items-center justify-center gap-x-2 rounded-lg border font-semibold focus:outline-none disabled:opacity-50',
  variants: {
    padding: {
      medium:
        'px-[calc(theme(spacing[2.5])-1px)] py-[calc(theme(spacing[1.5])-1px)] text-xs sm:px-[calc(theme(spacing.3)-1px)] sm:py-[calc(theme(spacing[1.5])-1px)] sm:text-sm/6',
      small:
        'px-[calc(theme(spacing[2])-1px)] py-[calc(theme(spacing[1])-1px)] text-xs sm:px-[calc(theme(spacing.2)-1px)] sm:py-[calc(theme(spacing[1])-1px)] sm:text-sm/5',
    },
  },
  defaultVariants: {
    padding: 'medium',
  },
})

export const solidBtn = tv({
  extend: baseBtn,
  base: 'border-transparent bg-[--btn-border] before:absolute before:inset-0 before:-z-10 before:rounded-[calc(theme(borderRadius.lg)-1px)] before:bg-[--btn-bg] before:shadow after:absolute after:inset-0 after:-z-10 after:rounded-[calc(theme(borderRadius.lg)-1px)] after:shadow-[shadow:inset_0_1px_theme(colors.white/15%)] after:active:bg-[--btn-hover-overlay] after:hover:bg-[--btn-hover-overlay] pressed:ring-2 before:disabled:shadow-none after:disabled:shadow-none dark:border-white/5 dark:bg-[--btn-bg] dark:before:hidden dark:after:-inset-px dark:after:rounded-lg',
  variants: {
    backgroundColor: {
      brand:
        'text-white [--btn-bg:theme(colors.brand.600)] [--btn-border:theme(colors.brand.700/90%)] [--btn-hover-overlay:theme(colors.brand.500/50%)] pressed:ring-brand-300',
      primary:
        'text-white [--btn-bg:theme(colors.slate.900)] [--btn-border:theme(colors.slate.950/90%)] [--btn-hover-overlay:theme(colors.white/10%)] pressed:ring-slate-300 dark:[--btn-hover-overlay:theme(colors.white/5%)]',
    },
  },
  defaultVariants: {
    backgroundColor: 'brand',
  },
})

export const plainBtn = tv({
  extend: baseBtn,
  base: 'border-transparent text-slate-600 will-change-transform forced-color-adjust-none',
  variants: {
    backgroundColor: {
      brand:
        'hover:bg-brand-600 hover:text-white dark:hover:bg-brand-500 dark:hover:text-white',
      primary:
        'hover:bg-slate-200 hover:text-slate-700 dark:text-slate-400 dark:hover:bg-white/10 dark:hover:text-slate-200',
    },
    isCurrent: {
      true: 'bg-slate-800/5 text-brand-600 hover:bg-slate-800/10 dark:bg-white/5 dark:text-brand-600 dark:hover:bg-white/10',
    },
  },
  defaultVariants: {
    backgroundColor: 'primary',
  },
})

export const outlineBtn = tv({
  extend: baseBtn,
  base: 'border-slate-950/10 text-slate-950 active:bg-slate-950/[2.5%] hover:bg-slate-950/[2.5%] dark:border-white/15 dark:text-white dark:[--btn-bg:transparent] dark:active:bg-white/5 dark:hover:bg-white/5',
})

function removeProp<A extends object>(props: A) {
  return collect(props).forget('buttonType').items as A
}

export function getButtonProps<A extends ComponentType>(
  props: ButtonStyleAttributes & ComponentPropsWithRef<A>,
) {
  switch (props.buttonType) {
    case ButtonType.SOLID: {
      const { backgroundColor, padding, className, ...rest } = props
      return {
        ...removeProp(rest),
        className: solidBtn({ backgroundColor, padding, className }),
      }
    }
    case ButtonType.PLAIN: {
      const { padding, isCurrent, backgroundColor, className, ...rest } = props
      return {
        ...removeProp(rest),
        className: plainBtn({ padding, isCurrent, backgroundColor, className }),
      }
    }
    case ButtonType.OUTLINE: {
      const { padding, className, ...rest } = props
      return {
        ...removeProp(rest),
        className: outlineBtn({ padding, className }),
      }
    }
    case ButtonType.LINK: {
      const {
        intent,
        size,
        colorScheme,
        isCurrent,
        hoverStyle,
        className,
        ...rest
      } = props
      return {
        ...removeProp(rest),
        className: baseLink({
          intent,
          size,
          colorScheme,
          isCurrent,
          hoverStyle,
          className,
        }),
      }
    }
  }
}

type LocaleLinkProps = Omit<GatsbyLinkProps<Record<string, unknown>>, 'to'> & {
  to: string
  language?: AppLanguages
}

export type AppLinkProps = LocaleLinkProps & ButtonStyleAttributes

export function getInternalLinkProps(props: AppLinkProps) {
  switch (props.buttonType) {
    case ButtonType.LINK: {
      const { intent, size, colorScheme, hoverStyle, className, ...rest } =
        props
      return {
        ...removeProp(rest),
        getProps: ({ isCurrent }: { isCurrent: boolean }) => ({
          className: baseLink({
            isCurrent,
            intent,
            size,
            colorScheme,
            hoverStyle,
            className,
          }),
        }),
      }
    }
    case ButtonType.PLAIN: {
      const { padding, backgroundColor, className, ...rest } = props
      return {
        ...removeProp(rest),
        getProps: ({ isCurrent }: { isCurrent: boolean }) => ({
          className: plainBtn({
            isCurrent,
            padding,
            backgroundColor,
            className,
          }),
        }),
      }
    }
    case ButtonType.OUTLINE: {
      const { padding, className, ...rest } = props
      return {
        ...removeProp(rest),
        className: outlineBtn({ padding, className }),
      }
    }
    case ButtonType.SOLID: {
      const { backgroundColor, padding, className, ...rest } = props
      return {
        ...removeProp(rest),
        className: solidBtn({ backgroundColor, padding, className }),
      }
    }
    default:
      return props
  }
}

export type AnchorLinkProps = Omit<AriaLinkProps, 'className'> &
  ButtonStyleAttributes
