import type { Action } from '@reduxjs/toolkit'
import { createAction } from '@reduxjs/toolkit/react'

import type { RootState } from '@/store/app-store'
import { withPayloadType } from '@/store/app-store-helpers'
import { postsAdapter } from '@/store/features/posts/posts-slice'
import type { PostData, PostsQuery } from '@/types/posts-types'

export const postReceived = createAction(
  'POSTS/POST_RECEIVED',
  withPayloadType<PostsQuery>(),
)

export const allPostsReceived = createAction<PostsQuery[]>('POSTS/LOAD_POSTS')

export function loadPosts(state: RootState['postsSlice'], action: Action) {
  if (allPostsReceived.match(action)) {
    if (action.payload.length > 0) {
      const posts = transformData(action.payload)
      postsAdapter.addMany(state, posts)
    }
  }
}

export function addPosts(state: RootState['postsSlice'], action: Action) {
  if (postReceived.match(action)) {
    const hasPost = state.ids.includes(action.payload.slug)
    if (!hasPost) {
      const post = formatPost(action.payload)
      postsAdapter.addOne(state, post)
    }
  }
}

function formatPost(post: PostsQuery): PostData {
  return {
    ...post,
    category: post.categories.nodes[0].name,
    excerpt: post.newsType === 'NEWS_TYPE' ? post.newsContent : post.jobsIntro,
    isJob: post.newsType === 'JOB_TYPE',
  }
}

function transformData(posts: PostsQuery[]): PostData[] {
  return posts.map((post) => formatPost(post))
}
