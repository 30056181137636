import { tv } from 'tailwind-variants'

export const textVariants = tv({
  variants: {
    intent: {
      primary: 'font-semibold leading-relaxed tracking-tight',
      secondary: 'font-medium tracking-tight',
      inner: 'font-semibold leading-6 tracking-tight',
      base: 'font-semibold tracking-tight',
    },
    colorScheme: {
      brand: 'text-brand-600 dark:text-brand-500',
      primary: 'text-slate-800 dark:text-slate-200',
      secondary: 'text-slate-600 dark:text-slate-400',
      'light/primary': 'text-white',
      'light/secondary': 'text-slate-300',
      'dark/primary': 'text-slate-900',
      'dark/secondary': 'text-slate-700',
    },
    size: {
      large5xl: 'text-5xl sm:text-7xl',
      large3xl: 'text-3xl sm:text-5xl',
      large: 'text-2xl sm:text-4xl',
      medium: 'text-lg lg:text-xl',
      base: 'text-lg',
      small: 'text-base',
      xs: 'text-sm',
    },
  },
})
