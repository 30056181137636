import { type ForwardedRef, forwardRef } from 'react'
import {
  Button as RACButton,
  type ButtonProps as AriaButtonProps,
} from 'react-aria-components'

import { getButtonProps } from '@/components/ui/elements/variants/btn-link'
import { getCachedId } from '@/lib/id-helper'
import {
  type ButtonStyleAttributes,
  ButtonType,
} from '@/types/style-variants-types'

export type ButtonProps = Omit<AriaButtonProps, 'className'>

function ButtonComponent(
  {
    buttonType = ButtonType.SOLID,
    ...props
  }: ButtonStyleAttributes & ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const buttonProps = getButtonProps<typeof RACButton>({ buttonType, ...props })

  return (
    <RACButton
      {...buttonProps}
      id={getCachedId(props.id ?? 'button-component')}
      ref={ref}
    />
  )
}

export const Button = forwardRef(ButtonComponent)
