import { createApi } from '@reduxjs/toolkit/query/react'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { GraphQLClient } from 'graphql-request'

export const client = new GraphQLClient(process.env.GATSBY_BACKEND_URL)

export const api = createApi({
  reducerPath: 'appApi',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  baseQuery: graphqlRequestBaseQuery({ client }),
  endpoints: () => ({}),
})
