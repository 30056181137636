import { composeRenderProps } from 'react-aria-components'
import { twMerge } from 'tailwind-merge'
import { tv } from 'tailwind-variants'

/**
 * Compose and Merge Tailwind Classname
 * @param className
 * @param tw
 */
export function composeTailwindRenderProps<T>(
  className: string | ((v: T) => string) | undefined,
  tw: string,
): string | ((v: T) => string) {
  return composeRenderProps(className, (className) => twMerge(tw, className))
}

/**
 * Focus Ring
 */
export const focusRing = tv({
  base: 'outline outline-offset-2 outline-slate-600 dark:outline-slate-500',
  variants: {
    isFocusVisible: {
      false: 'outline-0',
      true: 'outline-2',
    },
  },
})

/**
 * Check If Is Object Key
 * @param x
 * @param k
 */
export function isObjectKey<T extends object>(
  x: T,
  k: PropertyKey,
): k is keyof T {
  return k in x
}

type Valuable<T> = {
  [K in keyof T as T[K] extends null | undefined ? never : K]: T[K]
}

/**
 * Remove null or undefined values from object
 * @param obj
 */
export function getValuable<
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends {},
  V = Valuable<T>,
>(obj: T): V {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([, v]) =>
        !(
          (typeof v === 'string' && !v.length) ||
          v === null ||
          typeof v === 'undefined'
        ),
    ),
  ) as V
}

/**
 * Filter Object By Keys
 * @param obj
 * @param keys
 */
export function filterObject<T extends object>(
  obj: T,
  keys: string | string[],
) {
  return Object.fromEntries(
    Object.entries(obj).filter(([k]) => keys.includes(k)),
  ) as T
}

export function pickFromObject<T extends object>(obj: T, key: string) {
  return filterObject(obj, key)[key as keyof T]
}

/**
 * Check If Object Is Empty
 * @param obj
 */
export function isEmptyObject<T extends object>(obj: T) {
  return Object.keys(obj).length === 0
}

/**
 * Generic Object Keys Types
 * @param obj
 */
export const objectKeys = <T extends object>(obj: T) => {
  return Object.keys(obj) as Array<keyof T>
}

/**
 * Array From Number
 * @param n
 */
export const range = (n: number) => Array.from({ length: n }, (_, i) => i + 1)

/**
 * Wrap Range For Infinite Carousel/Scrolling
 * @param min
 * @param max
 * @param v
 */
export const wrap = (min: number, max: number, v: number) => {
  const rangeSize = max - min
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min
}

/**
 * Delay execution
 * @param ms
 */
export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms))

/**
 * Format String To Title Case
 * @param str
 */
export const toTitleCase = (str?: string) => {
  if (str) {
    const strMatches = str.match(
      /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g,
    )
    if (strMatches) {
      return strMatches
        .map((x) => x.slice(0, 1).toUpperCase() + x.slice(1))
        .join(' ')
    }
    return str
  }
  return ''
}

/**
 * Check If String Is Valid Url
 * @param url
 */
export const isValidUrl = (url: string): boolean => {
  try {
    return Boolean(new URL(url))
  } catch (e) {
    return false
  }
}

/**
 * Check If Is Imgix Url
 * @param url
 */
export const isImgixUrl = (url: string) => {
  if (isValidUrl(url)) {
    return url.includes('imgix')
  }
  return false
}

export function isInternalLink(url: string) {
  return /^\/(?!\/)/.test(url)
}

export function getArticlePath(path: string) {
  const isArticlePath = path.match(/^\/novosti\/(.*)/)
  if (!isArticlePath) return path
  let pathname = window?.location.pathname
  if (pathname.endsWith(`/`)) {
    pathname = pathname.slice(0, -1)
  }
  const slug = pathname.slice(pathname.lastIndexOf('/') + 1)
  path = path.replace('[slug]', slug)
  return path
}
