import { createSlice, type PayloadAction } from '@reduxjs/toolkit/react'

import { galleryImagesAdapter } from '@/store/features/gallery/gallery-api'
import {
  galleryReceived,
  onGalleryReceived,
  onProjectsGalleryReceived,
  projectsGalleryReceived,
} from '@/store/features/gallery/gallery-images-actions'

export const galleryImagesState = galleryImagesAdapter.getInitialState({
  lightBoxIndex: -1,
  selectedSlug: '',
  lightBoxOpen: false,
})

export const galleryImagesSlice = createSlice({
  name: 'galleryImages',
  initialState: galleryImagesState,
  reducers: {
    setLightBoxIndex(
      state,
      action: PayloadAction<{ index: number; slug: string }>,
    ) {
      if (state.selectedSlug !== action.payload.slug) {
        state.selectedSlug = action.payload.slug
      }
      state.lightBoxIndex = action.payload.index
    },
  },
  extraReducers: (builder) => {
    builder.addCase(galleryReceived, onGalleryReceived)
    builder.addCase(projectsGalleryReceived, onProjectsGalleryReceived)
    builder.addMatcher<PayloadAction<{ index: number; slug: string }>>(
      (action) => galleryImagesSlice.actions.setLightBoxIndex.match(action),
      (state, action) => {
        state.lightBoxOpen =
          action.payload.index > -1 && action.payload.slug.length > 1
      },
    )
  },
})

export const { setLightBoxIndex } = galleryImagesSlice.actions

export default galleryImagesSlice.reducer
