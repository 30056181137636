import { wrap } from '@suspensive/react'
import { type PropsWithChildren } from 'react'

import { AppRouterProvider } from '@/components/site-components/app-router-provider'
import { ErrorBoundaryFallback } from '@/components/site-components/error-boundary-fallback'

export const AppLayoutWrapper = wrap
  .ErrorBoundary({ fallback: ErrorBoundaryFallback })
  .Suspense()
  .on(({ children }: PropsWithChildren) => {
    return <AppRouterProvider>{children}</AppRouterProvider>
  })
