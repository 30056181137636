import { m, useScroll, useSpring, useTransform } from 'motion/react'

import { Button } from '@/components/ui/elements/button'
import { useScrollTo } from '@/hooks/useScrollTo'
import { ButtonType } from '@/types/style-variants-types'

const MotionButton = m.create(Button)

export function ScrollToTop() {
  const { handlePress } = useScrollTo()
  const { scrollYProgress } = useScroll()
  const display = useTransform(scrollYProgress, [0.2, 0.3], ['none', 'block'])
  const pathLength = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  })

  return (
    <div className={'fixed bottom-10 right-6 z-50'}>
      <MotionButton
        buttonType={ButtonType.LINK}
        style={{ display: display }}
        className={'focus:outline-none pressed:outline-none'}
        onPress={handlePress}
      >
        <svg
          id="progress"
          width="65"
          height="65"
          viewBox="0 0 100 100"
          className="fill-gray-600 hover:fill-red-600/50"
        >
          <circle
            cx="50"
            cy="50"
            r="30"
            fill="none"
            pathLength="1"
            className="stroke-slate-900 stroke-[4px] dark:stroke-slate-100"
          ></circle>
          <m.circle
            cx="50"
            cy="50"
            r="30"
            pathLength="1"
            className="stroke-brand-600 stroke-[4px]"
            style={{ pathLength }}
          />
          <path
            className="translate-x-[26px] translate-y-[18px] fill-brand-600"
            fill="none"
            d="M42 38L39 41 24 26.147 9 41 6 38 24 20z"
          />
        </svg>
      </MotionButton>
    </div>
  )
}
