// noinspection GraphQLUnresolvedReference

import { gql } from 'graphql-request'

export const GALLERY_IMAGE_FRAGMENT = gql`
  fragment GalleryImage on MediaItem {
    databaseId
    mediaDetails {
      file
      height
      width
    }
    altText
    mediaItemUrl
    title
  }
`

export const GET_GALLERY_BY_SLUG = gql`
  query GetGalleryBySlug($id: ID!) {
    mediaGallery(id: $id, idType: SLUG) {
      databaseId
      slug
      galleryImages {
        nodes {
          ...GalleryImage
        }
      }
    }
  }
  ${GALLERY_IMAGE_FRAGMENT}
`

export const GET_PROJECTS_GALLERIES = gql`
  query GetProjectsGallery {
    mediaGalleries(where: { categoryName: "projects" }) {
      nodes {
        databaseId
        slug
        title
        year
        galleryImages {
          nodes {
            ...GalleryImage
          }
        }
      }
    }
  }
  ${GALLERY_IMAGE_FRAGMENT}
`
