type JsCacheStore<TKey extends string, TValue> = {
  [key: symbol]: Map<TKey, TValue>
}

const jsCacheStore: JsCacheStore<string, unknown> = {}

const defaultNamespace = Symbol('defaultNamespace')

/**
 * Generate Cache For Namespace
 * @param namespace
 */
function cacheNamespace<TKey extends string, TValue>(namespace: symbol) {
  if (!jsCacheStore[namespace]) {
    //change from an object to a map
    jsCacheStore[namespace] = new Map()
  }

  function get(key: TKey): TValue {
    return jsCacheStore[namespace].get(key) as TValue
  }

  function set(key: TKey, value: TValue) {
    jsCacheStore[namespace].set(key, value)
  }

  function remove(key: TKey) {
    jsCacheStore[namespace].delete(key)
  }

  function removeAll() {
    jsCacheStore[namespace].clear()
  }

  return { get, set, remove, removeAll }
}

/**
 * Default Cache Instance
 */
export const { get, set, remove, removeAll } = cacheNamespace(defaultNamespace)

/**
 * Create Cached Instance
 * @param namespace
 */
export const createCacheInstance = <TKey extends string, TValue>(
  namespace: symbol,
) => cacheNamespace<TKey, TValue>(namespace)
