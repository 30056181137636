import { createSlice } from '@reduxjs/toolkit/react'
import { createIdSelector, createSelector } from 'redux-views'

import type { RootState } from '@/store/app-store'

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    dropDownId: null,
  },
  reducers: {
    setDropDownId: (state, action) => {
      state.dropDownId = action.payload
    },
  },
})

export const { setDropDownId } = navigationSlice.actions

export default navigationSlice.reducer

export const selectNavigationState = (state: RootState) => state.navigation

export const getDropDownId = createIdSelector<{ dropDownId: string }>(
  (props) => props.dropDownId,
)

export const selectDropDownId = createSelector(
  [selectNavigationState, getDropDownId],
  (state, dropDownId) => state.dropDownId === dropDownId,
)
