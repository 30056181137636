export const THEME_STORAGE_KEY = process.env.GATSBY_THEME_STORAGE_KEY

export const IS_SERVER = typeof window === 'undefined'

export const MAP_API_KEY = process.env.GOOGLE_MAPS_API_KEY

export const MAPBOX_PUBLIC_TOKEN = process.env.MAPBOX_ACCESS_TOKEN

export const MAPBOX_STREET_STYLE = 'mapbox://styles/mapbox/streets-v12'

export const MAPBOX_CENTER = {
  latitude: 43.8597,
  longitude: 18.2291,
  zoom: 14.25,
}

export const MARKER_CENTER = {
  latitude: 43.8577,
  longitude: 18.2291,
}

export const MOBILE_DEVICE = '(min-width: 320px) and (max-width: 640px)'

export const TABLET_DEVICE = '(min-width: 640px) and (max-width: 768px)'

export const DESKTOP_DEVICE_LG = '(min-width: 768px) and (max-width: 1024px)'

export const DESKTOP_DEVICE = '(min-width: 1024px)'

export const ACTION_NAMESPACE = '04969f90-b130-4ac7-93e4-1846fd17a759'

export const DISCLOSURE_NAMESPACE = '7f880754-ab58-49b0-9408-f91f051e1072'

export const MAPS_NAMESPACE = 'e193502a-2d59-4161-8b8e-c3c76bb89679'

export const BUTTON_NAMESPACE = '85710ce5-b946-4a5b-9d0c-0d4bbc1b3904'

export const SITE_URL = process.env.SITE_URL

export const ALPHANUMERIC_VALIDATION_REGEX =
  /^[a-z\u0161\u0111\u010D\u0107\u017E ]+$/gi

export const ACCEPTED_FILE_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
]
