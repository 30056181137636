import type { IImgixGatsbyImageDataArgsResolved } from '@imgix/gatsby/dist/modules/gatsby-plugin/privateTypes'
import { buildGatsbyImageDataObject } from '@imgix/gatsby/dist/pluginHelpers.browser'
import collect from 'collect.js'
import type { IGatsbyImageData } from 'gatsby-plugin-image'

import { isImgixUrl } from '@/lib/helpers'
import { createCacheInstance } from '@/lib/js-cache'
import type { WPMediaItem } from '@/types/gallery-types'

export type ImgixServiceFunctions = 'getImage' | 'getGalleryImage' | 'getThumb'

export type ImgixClientOptions = {
  domain: string
  secureURLToken: string
}

const imgixService = {
  cacheInstance: createCacheInstance<string, IGatsbyImageData>(
    Symbol('IMGIX_SERVICE'),
  ),
  imgixClient: collect<ImgixClientOptions>({
    domain: process.env.GATSBY_IMGIX_DOMAIN,
    secureURLToken: process.env.IMGIX_URL_TOKEN,
  }),
  getClientOptions: (file: string) => {
    if (isImgixUrl(file)) {
      const imgixClientOptions = imgixService.imgixClient.only([
        'secureURLToken',
      ]).items
      return {
        url: file,
        imgixClientOptions,
      }
    }
    return {
      url: file,
      imgixClientOptions: imgixService.imgixClient.items,
    }
  },
  loadImage: (
    image: WPMediaItem,
    params: IImgixGatsbyImageDataArgsResolved,
    suffix: string,
  ) => {
    let imageData = imgixService.cacheInstance.get(
      `${image.mediaDetails.file}-${suffix}`,
    )
    if (!imageData) {
      imageData = getImgixBuilder({ image, ...params })
      imgixService.cacheInstance.set(
        `${image.mediaDetails.file}-${suffix}`,
        imageData,
      )
    }
    return imageData
  },
  getGalleryImage: (image: WPMediaItem) => {
    const params = {
      imgixParams: {
        w: image.mediaDetails.width > 1000 ? '0.5' : '0.7',
      },
    }
    return imgixService.loadImage(image, params, 'gallery')
  },
  getSlideImage: (image: WPMediaItem) => {
    const params = {
      imgixParams: {
        lossless: 0,
        w: image.mediaDetails.width > 1000 ? '0.7' : '0.9',
      },
    }
    return imgixService.loadImage(image, params, 'slide')
  },
  getThumb: (image: WPMediaItem) => {
    const params = {
      width: 250,
      height: 167,
      imgixParams: {
        w: 250,
        fit: 'crop',
      },
    }
    return imgixService.loadImage(image, params, 'thumb')
  },
}

export const { getGalleryImage, getSlideImage, getThumb } = imgixService

export interface UseImgixBuilder<T extends string | WPMediaItem>
  extends IImgixGatsbyImageDataArgsResolved {
  image: T
}

function resolveImgUrl<T extends string | WPMediaItem, R = string>(
  image: T,
): R {
  if (typeof image === 'string') {
    return image as R
  }
  return image.mediaDetails.file as R
}

function getResizeOptions<T extends string | WPMediaItem>(
  image: T,
  scale?: number | string,
  width?: number,
  height?: number,
) {
  if (typeof image === 'string') {
    return {
      width: width || 640,
      height: height || 427,
    }
  }
  let w = image.mediaDetails.width
  let h = image.mediaDetails.height
  if (width) {
    w = width
  }
  if (height) {
    h = height
  }
  if (scale) {
    scale = typeof scale === 'number' ? scale : parseFloat(scale)
    if (scale <= 1) {
      w = w * scale
      h = h * scale
    }
    return {
      width: Math.round(w),
      height: Math.round(h),
    }
  }
  return {
    width: Math.round(w),
    height: Math.round(h),
  }
}

export const getImgixBuilder = <T extends string | WPMediaItem>({
  image,
  imgixParams,
  layout,
  ...props
}: UseImgixBuilder<T>) => {
  const url = resolveImgUrl(image)
  const clientOptions = imgixService.getClientOptions(url)
  const { width, height, placeholder, ...rest } = props
  const scale = (imgixParams?.w || imgixParams?.h) as
    | string
    | number
    | undefined
  const imgSize = getResizeOptions(image, scale, width, height)
  return buildGatsbyImageDataObject({
    ...clientOptions,
    resolverArgs: {
      layout: layout ?? 'constrained',
      imgixParams: {
        auto: 'format',
        fit: layout !== 'fullWidth' ? 'clip' : 'crop',
        ...imgixParams,
      },
      placeholder: placeholder ?? 'blurred',
      ...rest,
    },
    dimensions: { width: imgSize.width, height: imgSize.height },
  })
}
