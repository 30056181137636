import type { ComponentPropsWithoutRef, ElementType, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import { tv } from 'tailwind-variants'

import { borderRing } from '@/components/ui/elements/variants/border-variants'

export const card = tv({
  base: 'group relative rounded-xl md:rounded-2xl',
  variants: {
    variant: {
      primary:
        'bg-white transition duration-500 hover:scale-[1.02] hover:bg-slate-50 dark:bg-black/80 dark:hover:bg-black/75',
      secondary: 'bg-white shadow-xl shadow-slate-900/10 dark:bg-black',
      footerCard:
        'relative m-6 bg-black shadow md:flex md:items-center md:justify-between md:p-6',
      gray: 'overflow-hidden bg-gray-950 text-center text-gray-100 ring ring-slate-800 ring-offset-2 ring-offset-gray-900',
    },
    hoverStyle: {
      true: borderRing.hoverRing,
    },
    flexStyle: {
      true: 'flex w-full flex-col p-4',
    },
  },
  compoundVariants: [
    {
      variant: ['primary', 'secondary'],
      className: `${borderRing.ring} ${borderRing.focusRing}`,
    },
  ],
})

export const CardDefaultAsType = 'div' as const
export type CardDefaultAsType = typeof CardDefaultAsType

export interface CardProps<T extends ElementType> {
  variant?: 'primary' | 'secondary' | 'footerCard' | 'gray'
  bordered?: boolean
  hoverStyle?: boolean
  flexStyle?: boolean
  className?: string
  as?: T
  children: ReactNode
}

export function Card<T extends ElementType = CardDefaultAsType>({
  variant,
  bordered,
  hoverStyle,
  flexStyle,
  as,
  className,
  children,
  ...props
}: CardProps<T> & ComponentPropsWithoutRef<T>) {
  const Component = as || CardDefaultAsType

  return (
    <Component
      {...props}
      className={card({ variant, hoverStyle, flexStyle, className })}
    >
      {bordered && (
        <div className="absolute -inset-px rounded-xl border-2 border-transparent opacity-0 [background:linear-gradient(var(--link-grid-hover-bg,theme(colors.sky.50)),var(--link-grid-hover-bg,theme(colors.sky.50)))_padding-box,linear-gradient(to_top,theme(colors.indigo.400),theme(colors.red.400),theme(colors.rose.500))_border-box] group-hover:opacity-100 dark:[--link-grid-hover-bg:theme(colors.slate.900)]" />
      )}
      {children}
    </Component>
  )
}

const cardBody = tv({
  variants: {
    variant: {
      primary: 'relative',
      flexed: 'relative flex flex-col',
    },
    size: {
      base: 'p-4',
      large: 'p-6',
    },
  },
})

export interface CardBodyProps {
  variant?: 'primary' | 'flexed'
  size?: 'base' | 'large'
  className?: string
  children: ReactNode
}

export function CardBody({
  variant,
  size,
  children,
  className,
}: CardBodyProps) {
  return (
    <div className={cardBody({ variant, size, className })}>{children}</div>
  )
}

export interface CardHeaderProps {
  className?: string
  children?: ReactNode
}

export function CardHeader({ children, className }: CardHeaderProps) {
  return (
    <div className={twMerge('px-4 py-5 sm:px-6', className)}>{children}</div>
  )
}
