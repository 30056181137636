import type { Action } from '@reduxjs/toolkit'
import { createAction } from '@reduxjs/toolkit/react'
import type { IGatsbyImageData } from 'gatsby-plugin-image'
import type { ImgixSlide } from 'yet-another-react-lightbox'

import { getGalleryImage, getSlideImage, getThumb } from '@/lib/imgix-builder'
import type { RootState } from '@/store/app-store'
import { withPayloadType } from '@/store/app-store-helpers'
import { galleryImagesAdapter } from '@/store/features/gallery/gallery-api'
import type {
  GetGalleryBySlugQuery,
  GetProjectsGalleryQuery,
} from '@/store/features/gallery/gallery-query-types'
import type { WPMediaItem } from '@/types/gallery-types'

export const galleryReceived = createAction(
  'GALLERY_IMAGES/GALLERY_RECEIVED',
  withPayloadType<GetGalleryBySlugQuery>(),
)

export const projectsGalleryReceived = createAction(
  'GALLERY_IMAGES/GALLERY_PROJECTS',
  withPayloadType<GetProjectsGalleryQuery>(),
)

export function formatGallery(images: WPMediaItem[]) {
  return images.map((image) => ({
    altText: image.altText,
    title: image.title,
    gallery: getGalleryImage(image) as IGatsbyImageData,
    slide: {
      type: 'imgix-slide',
      slideImage: getSlideImage(image),
      thumbImage: getThumb(image),
    } as ImgixSlide,
  }))
}

export function onGalleryReceived(
  state: RootState['galleryImages'],
  action: Action,
) {
  if (galleryReceived.match(action)) {
    const payloadData = action.payload.mediaGallery
    const galleryImages = formatGallery(payloadData.galleryImages.nodes)
    galleryImagesAdapter.addOne(state, {
      slug: payloadData.slug,
      galleryImages,
    })
  }
}

export function onProjectsGalleryReceived(
  state: RootState['galleryImages'],
  action: Action,
) {
  if (projectsGalleryReceived.match(action)) {
    const payloadData = action.payload.mediaGalleries.nodes
    const entities = payloadData.map((node) => ({
      slug: node.slug,
      galleryImages: formatGallery(node.galleryImages.nodes),
    }))
    galleryImagesAdapter.addMany(state, entities)
  }
}
