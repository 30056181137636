import { LazyMotion, MotionConfig } from 'motion/react'
import type { ReactNode } from 'react'

const loadDomAnimations = () =>
  import('../../../lib/framer-dom-animations').then((res) => res.default)

export interface DomAnimationProps {
  children?: ReactNode
}

export function DomAnimations({ children }: DomAnimationProps) {
  return (
    <LazyMotion features={loadDomAnimations}>
      <MotionConfig reducedMotion={'user'}>{children}</MotionConfig>
    </LazyMotion>
  )
}
