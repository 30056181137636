import { ClientOnly } from '@suspensive/react'
import { type PageProps, Slice } from 'gatsby'
import type { ReactNode } from 'react'

import { AppLayoutWrapper } from '@/components/site-components/app-layout-wrapper'
import PageTransition from '@/components/site-components/page-transition'
import { ScrollToTop } from '@/components/ui/content/scroll-to-top'
import { DomAnimations } from '@/components/ui/loaders/dom-animations'
import type { PageContextType } from '@/types/page-context-types'

export interface AppLayoutProps
  extends Omit<
    PageProps<Record<string, unknown>, PageContextType>,
    'children'
  > {
  children: ReactNode
}

export default function AppLayout({ children, pageContext }: AppLayoutProps) {
  return (
    <AppLayoutWrapper>
      <DomAnimations>
        <PageTransition page={pageContext.i18n.path}>
          {children}
          <Slice alias={'main-footer'} />
        </PageTransition>
        <ClientOnly>
          <ScrollToTop />
        </ClientOnly>
      </DomAnimations>
    </AppLayoutWrapper>
  )
}
