import { useErrorBoundaryFallbackProps } from '@suspensive/react'

import { AppLogo } from '@/components/site-components/app-logo'
import { Button } from '@/components/ui/elements/button'
import { Card, CardBody, CardHeader } from '@/components/ui/elements/card'
import { Heading } from '@/components/ui/elements/heading'

export function ErrorBoundaryFallback() {
  const { reset, error } = useErrorBoundaryFallbackProps()
  return (
    <main
      className={
        'grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8 dark:bg-slate-950'
      }
    >
      <Card variant={'gray'} className={'divide-y divide-gray-900'}>
        <CardHeader className={'flex items-center justify-center'}>
          <AppLogo />
        </CardHeader>
        <CardBody
          variant={'flexed'}
          size={'large'}
          className={'items-center justify-center'}
        >
          <Heading
            level={1}
            intent={'inner'}
            size={'base'}
            colorScheme={'light/secondary'}
          >
            {error.message}
          </Heading>
          <div className={'mt-10 flex items-center gap-4'}>
            <Button onPress={reset}>Osvježi</Button>
          </div>
        </CardBody>
      </Card>
    </main>
  )
}
