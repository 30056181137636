import { createSlice, type PayloadAction } from '@reduxjs/toolkit/react'

import { MAPBOX_CENTER, MAPBOX_STREET_STYLE } from '@/lib/constants'

export interface MapViewState {
  latitude: number
  longitude: number
  zoom: number
}

export interface MapState {
  mapStyle: string
  viewState: MapViewState
  showPopup: boolean
}

const initialState: MapState = {
  mapStyle: MAPBOX_STREET_STYLE,
  viewState: MAPBOX_CENTER,
  showPopup: true,
}

export const mapSlice = createSlice({
  name: 'mapBox',
  initialState,
  reducers: {
    setViewState: (state, action: PayloadAction<MapViewState>) => {
      state.viewState = action.payload
    },
    togglePopup: (state) => {
      state.showPopup = !state.showPopup
    },
  },
})

export const { setViewState, togglePopup } = mapSlice.actions

export default mapSlice.reducer
