import type { NavigateOptions } from '@reach/router'
import type { ReactNode } from 'react'
import { RouterProvider } from 'react-aria-components'

import useNavigate from '@/hooks/use-navigate'

declare module 'react-aria-components' {
  interface RouterConfig {
    routerOptions: NavigateOptions<Record<string, unknown>>
  }
}

export interface AppRouterProviderProps {
  children: ReactNode
}

export function AppRouterProvider({ children }: AppRouterProviderProps) {
  const { navigateAction } = useNavigate()
  return <RouterProvider navigate={navigateAction}>{children}</RouterProvider>
}
