// noinspection GraphQLUnresolvedReference

import { gql } from 'graphql-request'

export const JOB_FRAGMENT = gql`
  fragment JobFragment on Post {
    jobsConclusion(format: RENDERED)
    jobsIntro(format: RENDERED)
    jobsOffer(format: RENDERED)
    jobsProfile(format: RENDERED)
    jobsResponsibilities(format: RENDERED)
  }
`

export const FEATURED_IMAGE_FRAGMENT = gql`
  fragment FeaturedImage on MediaItem {
    altText
    id
    mediaDetails {
      file
      width
      height
    }
    mediaItemUrl
  }
`

export const POST_FRAGMENT = gql`
  fragment PostFragment on Post {
    categories {
      nodes {
        name
      }
    }
    featuredImage {
      node {
        ...FeaturedImage
      }
    }
    id
    ...JobFragment
    language {
      slug
    }
    newsContent(format: RENDERED)
    newsType
    slug
    title
  }
`

export const ALL_POSTS_QUERY = gql`
  query AllPosts {
    posts(first: 5, where: { language: BS }) {
      nodes {
        ...PostFragment
        translations {
          ...PostFragment
        }
        __typename
      }
    }
  }
  ${JOB_FRAGMENT}
  ${FEATURED_IMAGE_FRAGMENT}
  ${POST_FRAGMENT}
`
export const GET_POST_QUERY = gql`
  query GetPost($id: ID!) {
    post(id: $id, idType: SLUG) {
      ...PostFragment
      translations {
        ...PostFragment
      }
    }
  }
  ${JOB_FRAGMENT}
  ${FEATURED_IMAGE_FRAGMENT}
  ${POST_FRAGMENT}
`
