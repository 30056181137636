import { createSlice, type PayloadAction } from '@reduxjs/toolkit/react'

export type ModalManagerKeys = 'mobile-menu' | 'job-posting' | 'form-alert'

export interface ModalManagerState {
  isModalOpen: boolean
  modalContext?: Record<string, unknown>
}

export type ModalManagers = Record<ModalManagerKeys, ModalManagerState>

const initialState: ModalManagers = {
  'mobile-menu': {
    isModalOpen: false,
  },
  'job-posting': {
    isModalOpen: false,
    modalContext: {
      selectedJob: '',
    },
  },
  'form-alert': {
    isModalOpen: false,
    modalContext: {
      message: '',
    },
  },
}

export const modalManagerSlice = createSlice({
  name: 'modalManager',
  initialState,
  reducers: {
    toggleModal: (
      state,
      action: PayloadAction<{
        modalManager: ModalManagerKeys
        value: boolean
      }>,
    ) => {
      const modal = action.payload.modalManager
      state[modal].isModalOpen = action.payload.value
    },
    addModalContext: (
      state,
      action: PayloadAction<{
        modalManager: ModalManagerKeys
        contextData: Record<string, unknown>
      }>,
    ) => {
      const modal = action.payload.modalManager
      if ('modalContext' in state[modal] && state[modal].modalContext) {
        Object.assign(state[modal].modalContext, action.payload.contextData)
      }
    },
  },
})

export const { toggleModal, addModalContext } = modalManagerSlice.actions

export default modalManagerSlice.reducer
