import type { GatsbySSR } from 'gatsby'
import { I18nextProvider } from 'react-i18next'

import type { PageContextType } from '@/types/page-context-types'

import AppLayout from './src/components/site-components/app-layout'

export const wrapWithLayout: GatsbySSR<
  Record<string, unknown>,
  PageContextType
>['wrapPageElement'] = ({ element, props }) => {
  const i18n = element.props.i18n
  return (
    <I18nextProvider i18n={i18n}>
      <AppLayout {...props}>{element}</AppLayout>
    </I18nextProvider>
  )
}
