import { v5 as uuidv5 } from 'uuid'

import { createCacheInstance } from '@/lib/js-cache'

const IdNameSpace = Symbol('ID_HELPER')

const helperCache = createCacheInstance<string, string>(IdNameSpace)

const DEFAULT_COMPONENT_NAMESPACE = '9b7dd82c-c8d3-4781-b7b4-b3d98ad51ba6'

export const getCachedId = (componentKey: string, defaultId?: string) => {
  let cachedId: string = helperCache.get(componentKey)
  if (!cachedId) {
    cachedId = defaultId
      ? uuidv5(defaultId, DEFAULT_COMPONENT_NAMESPACE)
      : uuidv5(componentKey, DEFAULT_COMPONENT_NAMESPACE)
    helperCache.set(componentKey, cachedId)
  }
  return cachedId
}
