import { createEntityAdapter } from '@reduxjs/toolkit'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit/react'

import { addPosts, loadPosts } from '@/store/features/posts/posts-actions'
import { allPostsMatcher, postMatcher } from '@/store/features/posts/posts-api'
import type { PostData } from '@/types/posts-types'

export const postsAdapter = createEntityAdapter({
  selectId: (post: PostData) => post.slug,
})

export const initialPostsState = postsAdapter.getInitialState({
  currentJob: '',
})

export const postsSlice = createSlice({
  name: 'postsSlice',
  initialState: initialPostsState,
  reducers: {
    setCurrentJob: (state, action: PayloadAction<string>) => {
      state.currentJob = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(allPostsMatcher, loadPosts)
    builder.addMatcher(postMatcher, addPosts)
  },
})

export const { setCurrentJob } = postsSlice.actions

export default postsSlice.reducer
