import { setupListeners } from '@reduxjs/toolkit/query/react'
import { type ReactNode, useRef } from 'react'
import { Provider } from 'react-redux'

import { type AppStore, makeStore } from '@/store/app-store'

export default function AppReduxProvider({
  children,
}: {
  children: ReactNode
}) {
  const storeRef = useRef<AppStore>()
  if (!storeRef.current) {
    storeRef.current = makeStore()
    setupListeners(storeRef.current.dispatch)
  }
  return <Provider store={storeRef.current}>{children}</Provider>
}
