export interface IntentVariants {
  primary?: string
  secondary?: string
  inner?: string
  base?: string
}

export interface ColorVariants {
  brand?: string
  primary?: string
  secondary?: string
  'light/primary'?: string
  'light/secondary': string
  'dark/primary'?: string
  'dark/secondary'?: string
}

export interface SizeVariants {
  large5xl?: string
  large3xl?: string
  large?: string
  medium?: string
  base?: string
  small?: string
  xs?: string
}

export interface PaddingVariants {
  medium?: string
  small?: string
}

export interface BackgroundColorVariants {
  brand?: string
  primary?: string
}

export type IntentScheme = 'primary' | 'secondary' | 'inner' | 'base'

export type ColorScheme =
  | 'brand'
  | 'primary'
  | 'secondary'
  | 'light/primary'
  | 'light/secondary'
  | 'dark/primary'
  | 'dark/secondary'

export type SizeScheme =
  | 'large5xl'
  | 'large3xl'
  | 'large'
  | 'medium'
  | 'base'
  | 'small'
  | 'xs'

export type PaddingScheme = 'medium' | 'small'

export type BackgroundColorScheme = 'brand' | 'primary'

export enum LinkType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export enum ButtonType {
  SOLID = 'solid',
  PLAIN = 'plain',
  OUTLINE = 'outline',
  LINK = 'link',
}

export interface TextVariants {
  intent?: IntentScheme
  colorScheme?: ColorScheme
  size?: SizeScheme
}

export interface BaseButtonVariants {
  buttonType?: ButtonType
  className?: string
}

export interface SolidButtonVariants extends BaseButtonVariants {
  buttonType?: ButtonType.SOLID
  padding?: PaddingScheme
  backgroundColor?: BackgroundColorScheme
}

export interface PlainButtonVariants extends BaseButtonVariants {
  buttonType?: ButtonType.PLAIN
  padding?: PaddingScheme
  isCurrent?: boolean
  backgroundColor?: BackgroundColorScheme
}

export interface OutlineButtonVariants extends BaseButtonVariants {
  buttonType?: ButtonType.OUTLINE
  padding?: PaddingScheme
}

export interface LinkButtonVariants extends BaseButtonVariants, TextVariants {
  buttonType?: ButtonType.LINK
  isCurrent?: boolean
  hoverStyle?: boolean
}

export type ButtonStyleAttributes =
  | SolidButtonVariants
  | PlainButtonVariants
  | OutlineButtonVariants
  | LinkButtonVariants
