import {
  createDraftSafeSelectorCreator,
  weakMapMemoize,
} from '@reduxjs/toolkit'

export function withPayloadType<T>() {
  return (t: T) => ({ payload: t })
}

export const createAppDraftSafeSelector =
  createDraftSafeSelectorCreator(weakMapMemoize)
