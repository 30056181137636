import { configureStore } from '@reduxjs/toolkit/react'

import { api as appApi } from '@/store/app-api'

import galleryImagesReducer from './features/gallery/gallery-images-slice'
import mapReducer from './features/map/map-slice'
import modalManagerReducer from './features/modal-manager/modal-manager-slice'
import navigationReducer from './features/navigation/main-navigation-slice'
import postsReducer from './features/posts/posts-slice'
import sidebarReducer from './features/sidebar/sidebar-slice'

export const makeStore = () => {
  return configureStore({
    reducer: {
      [appApi.reducerPath]: appApi.reducer,
      postsSlice: postsReducer,
      galleryImages: galleryImagesReducer,
      sidebar: sidebarReducer,
      navigation: navigationReducer,
      mapBox: mapReducer,
      modalManager: modalManagerReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['sidebar/setCurrentSection'],
          ignoredPaths: ['sidebar.currentSection'],
        },
      }).concat(appApi.middleware),
  })
}

export type AppStore = ReturnType<typeof makeStore>

export type RootState = ReturnType<AppStore['getState']>

export type AppDispatch = AppStore['dispatch']
