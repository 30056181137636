import {
  DefaultProps,
  DefaultPropsProvider,
  ErrorBoundaryGroup,
} from '@suspensive/react'
import type { ReactNode } from 'react'

import { LoadingSpinner } from '@/components/ui/loaders/loading-spinner'
import { Spinner } from '@/components/ui/svg-patterns/spinner'

const defaultProps = new DefaultProps({
  Delay: {
    ms: 1200,
    fallback: <Spinner />,
  },
  Suspense: {
    fallback: <LoadingSpinner />,
  },
})

export default function AppSuspenseProvider({
  children,
}: {
  children: ReactNode
}) {
  return (
    <ErrorBoundaryGroup>
      <DefaultPropsProvider defaultProps={defaultProps}>
        {children}
      </DefaultPropsProvider>
    </ErrorBoundaryGroup>
  )
}
