import type { SVGAttributes, SVGProps } from 'react'

export interface SvgComponentProps extends SVGAttributes<SVGSVGElement> {
  viewBox?: string
}

export function SvgComponent(props: SvgComponentProps) {
  return (
    <svg fill="currentColor" {...props}>
      {props.children}
    </svg>
  )
}

export function SvgPath(props: SVGProps<SVGPathElement>) {
  return <path {...props} />
}
